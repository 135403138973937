import Swiper from 'swiper';
import { Navigation, EffectFade, Autoplay } from 'swiper/modules';

const theme = [
    {
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'landscape.natural',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#f5f5f2',
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'administrative',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.attraction',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi.business',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.medical',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.place_of_worship',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.school',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.sports_complex',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        stylers: [
            {
                visibility: 'simplified',
            },
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.icon',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'road.local',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'water',
        stylers: [
            {
                color: '#71c8d4',
            },
        ],
    },
    {
        featureType: 'landscape',
        stylers: [
            {
                color: '#e5e8e7',
            },
        ],
    },
    {
        featureType: 'poi.park',
        stylers: [
            {
                color: '#8ba129',
            },
        ],
    },
    {
        featureType: 'road',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'poi.sports_complex',
        elementType: 'geometry',
        stylers: [
            {
                color: '#c7c7c7',
            },
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'water',
        stylers: [
            {
                color: '#a0d3d3',
            },
        ],
    },
    {
        featureType: 'poi.park',
        stylers: [
            {
                color: '#91b65d',
            },
        ],
    },
    {
        featureType: 'poi.park',
        stylers: [
            {
                gamma: 1.51,
            },
        ],
    },
    {
        featureType: 'road.local',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi.government',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'landscape',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'road.local',
        stylers: [
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'road',
    },
    {
        featureType: 'road',
    },
    {},
    {
        featureType: 'road.highway',
    },
];

let map;
let markers = [];
let swiperInstances = [];

const swiperElements = document.querySelectorAll('.location-swiper');

window.ksInitGoogleMaps = async (mapElement) => {
    if (google?.maps === undefined) {
        return;
    }

    const { Map } = await google.maps.importLibrary('maps');
    const locations = JSON.parse(
        decodeURIComponent(mapElement.dataset.locations)
    );

    const geocoder = new google.maps.Geocoder();
    map = new google.maps.Map(mapElement, {
        zoom: 12,
        disableDefaultUI: true,
        styles: theme,
    });

    locations.forEach(function (location, index) {
        geocoder.geocode({ address: location }, function (results, status) {
            if (status === 'OK') {
                map.setCenter(results[0].geometry.location);
                markers[index] = new google.maps.Marker({
                    map: map,
                    position: results[0].geometry.location,
                });
                // Add click event listener to marker
                markers[index].addListener('click', function () {
                    swiper.slideTo(index);
                });
            } else {
                alert(
                    'Geocode was not successful for the following reason: ' +
                        status
                );
            }
        });
    });
};

swiperElements.forEach((swiperElement) => {
    const data = swiperElement.dataset.swiper;
    const options = JSON.parse(data);

    const swiper = new Swiper(swiperElement, {
        ...options,
        spaceBetween: 16,
        modules: [Navigation, EffectFade, Autoplay],
        on: {
            init: function () {
                // initGoogleMaps(this);
            },
            slideChange: function () {
                const activeIndex = this.activeIndex;
                if (markers[activeIndex]) {
                    // Make smooth transition to the marker
                    map.panTo(markers[activeIndex].getPosition());
                    // Add animation to the marker
                    markers[activeIndex].setAnimation(
                        google.maps.Animation.BOUNCE
                    );
                    // Stop animation after 2 bounces (approx. 1400ms)
                    setTimeout(
                        () => markers[activeIndex].setAnimation(null),
                        1400
                    );
                }
            },
        },
    });

    swiperInstances.push(swiper);
});

swiperInstances.forEach((swiper) => swiper.init());
