import { debounce } from "./utils";

export const navbar = () => {
    window.addEventListener('DOMContentLoaded', () => {
        const header = document.querySelector('.header');

        const toggleClass = () => {
            if (window.scrollY > 0) {
                header.classList.add("scrolled");
            } else {
                header.classList.remove("scrolled");
            }
        };

        window.addEventListener('scroll', debounce(toggleClass, 100));
        window.addEventListener('load', toggleClass);
    });
};
