// CSS
import 'swiper/css';
import './vendor/fontawesome/css/all.min.css';
import './styles/index.scss';

// JavaScript

// Bootstrap Modules
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/tab';

import './js/email_obfuscator';
import './js/swiper';
import './js/google_maps';
import { navbar } from './js/navbar';

(() => {
    navbar();
})();
