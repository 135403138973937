import Swiper from 'swiper';
import { Navigation, EffectFade, Controller } from 'swiper/modules';

const initSwiper = () => {
    const swiper = document.querySelectorAll('.swiper');

    if (swiper.length === 0) {
        return;
    }

    swiper.forEach((swiper) => {

        if (swiper.classList.contains('swiper-content') | swiper.classList.contains('swiper-image')) {
            return;
        }

        const data = swiper.dataset.swiper;
        const options = JSON.parse(data);

        new Swiper(swiper, {
            ...options,
            modules: [Navigation, EffectFade],
        });
    });
}


const initSyncedSwiper = () => {
    const syncedSwiper = document.querySelectorAll('.synced-swiper');

    if (syncedSwiper.length === 0) {
        return;
    }

    syncedSwiper.forEach((swiper) => {
        const dataSwiperContent = swiper.querySelector('.swiper-content').dataset.swiper;
        const dataSwiperImage = swiper.querySelector('.swiper-image').dataset.swiper;

        const swiperContent = new Swiper(swiper.querySelector('.swiper-content'), {
            ...JSON.parse(dataSwiperContent),
            modules: [Navigation, EffectFade, Controller],
        });

        const swiperImage = new Swiper(swiper.querySelector('.swiper-image'), {
            ...JSON.parse(dataSwiperImage),
            modules: [Navigation, EffectFade, Controller],
        });

        swiperContent.controller.control = swiperImage;
        swiperImage.controller.control = swiperContent;
    });
}

window.addEventListener('DOMContentLoaded', () => { initSyncedSwiper(); });
window.addEventListener('DOMContentLoaded', () => { initSwiper(); });
